
footer {
  padding-top: 2.1rem;
  border-top: 3px solid var(--bs-black);
  background: #f6f6f6;
}
.flex-logo {
  display: flex;
  gap: 1.8rem;
  align-items: center;
}
.footer-logo-space {
  margin-top: 63px;
}

footer ul {
  list-style: none;
  padding: 0;
}
footer ul li {
  margin-bottom: 1rem;
}
footer ul li a,
.copyright p {
  font-weight: 400;
  text-decoration: none;
}
footer h4,
footer ul li a,
footer .copyright p {
  color: #0d3862;
  font-size: 16px;
}
footer h4 {
  margin-bottom: 1.2rem;
  font-size: 18px;
  font-weight: 700;
  color: #0d3862;
}
.flex-social {
  display: flex;
  gap: 24px;
}
.flex-social li {
  width: 50px;
}
.copyright {
  border-top: 1px solid #4e4e4e;
  padding-top: 1.3rem;
  margin-top: 2.5rem !important;
  border-bottom: 1px solid #4e4e4e;
  margin-bottom: 10px;
}
.copyright p {
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 1rem;
}
.scrolled {
  top: 0;
  padding-top: 0;
  z-index: 999;
  background: #000;
}
.scrolled .navbar-expand-lg {
  background: #000;
}
.navbar-toggler-icon {
  background-image: url(../../images/bar-menu.svg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-image: url(../../images/cross-bar.svg) !important;
  transition: all 0s !important;
}
.no-cardp .card_name {
  display: block;
  border: none;
}
.no-cardp p {
  font-weight: 200;
  margin-top: 1.8rem;
}
.swiper-pagination-bullet {
  background: #b1afb0;
  opacity: 1;
  width: 12px;
  height: 12px;
}
.swiper-pagination-bullet-active {
  background: #ed1d51;
}
footer .col-md-4, footer .col-md-2{
  text-align: left;
}
.logo-sec .mobile-header_btn{
  display: none;
}

@media (max-width:767px) {
  .left_sec_footer{
    padding-left: 2rem;
  }
}