.bg-color-about {
  /* background: #0D3862 ;
    border: 1px solid #0D3862; */
  border-radius: 10px;
  margin-top: 100px;
}

.inner-smallspace-about {
  /* padding: 30px; */
}

h1 {
  /* color: #c02640;
  font-weight: bolder; */
}



p {
 
  /* font-weight: normal; */

}
