

/* header */
header {
  position: fixed;
  top: 0;
  padding: 1rem 0;
  background: #000;
  width: 100%;
}

header .navbar-nav {
  gap: 1.5rem;
}

header .navbar-nav .nav-item {
  position: relative;
}

header .navbar-nav .nav-link {
  color: var(--bs-white);
  font-size: 1.7rem;
  font-weight: 300;
  margin-right: 2rem;
}
header .navbar-nav .nav-item:last-child {
  background: #299203;
  border: 1px solid var(--bs-white);
  text-align: center;
  border-radius: 1.8rem;
}
header .navbar-nav .nav-item:nth-child(4) a::after {
  display: none;
}
header .navbar-nav .nav-item:last-child a {
  margin-right: 0;
  min-width: 100px;
}

header .navbar-nav .nav-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--bs-white);
}

header .navbar-nav .nav-item:last-child::after {
  display: none;
}

header .navbar-nav .nav-link.active {
  color: var(--bs-white);
}

header .navbar-brand img {
  height: 60px;
}
