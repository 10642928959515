:root {
  --gray: #606060;
  --dgray: #444444;
  --lgray: #ebebeb;
  --grayp: #808080;
  --grayf: #f6f6f6;
  --primary-font: "Source Sans 3", sans-serif;
  --gap: 5rem;
}

html {
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: 62.5%;
}

body {
  font-size: 1.8rem;
  font-weight: 300;
  font-family: var(--primary-font);
  color: var(--bs-white);
  background: var(--bs-black);
}

a {
  cursor: pointer !important;
}

/* Gap */

.sec-padd-t {
  padding-top: var(--gap);
}

.sec-padd-b {
  padding-bottom: var(--gap);
}

.sec-padd {
  padding: var(--gap) 0 var(--gap) 0;
}

/* Gap end */

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  letter-spacing: 1px;
}

a {
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 400;
  font-family: var(--primary-font);
}

img {
  max-width: 100%;
}

/* header */
/* header {
  position: fixed;
  top: 0;
  padding: 1rem 0;
  background: transparent;
  width: 100%;
}

header .navbar-nav {
  gap: 1.5rem;
}

header .navbar-nav .nav-item {
  position: relative;
}

header .navbar-nav .nav-link {
  color: var(--bs-white);
  font-size: 1.7rem;
  font-weight: 300;
  margin-right: 2rem;
}
header .navbar-nav .nav-item:last-child {
  background: #299203;
  border: 1px solid var(--bs-white);
  text-align: center;
  border-radius: 1.8rem;
}
header .navbar-nav .nav-item:nth-child(4) a::after {
  display: none;
}
header .navbar-nav .nav-item:last-child a {
  margin-right: 0;
  min-width: 100px;
}

header .navbar-nav .nav-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--bs-white);
}

header .navbar-nav .nav-item:last-child::after {
  display: none;
}

header .navbar-nav .nav-link.active {
  color: var(--bs-white);
}

header .navbar-brand img {
  height: 60px;
} */

.logo1 {
  height: 70px;
}

.home-banner {
  background: url("../../images/mainbannerimg.jpg") center center/ cover no-repeat;
  /* padding-top: 20rem;
    padding-bottom: 14rem; */
  height: 590px;
}

.home-banner h2 {
  font-size: 4.4rem;
  font-weight: 300;
  color: var(--bs-white);
}

.home-banner p {
  color: var(--bs-white);
}
.btn {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 1rem 2.4rem;
  padding-top: 1.2rem;
  border-radius: 4rem;
  transition: all 0.4s;
  min-width: 170px;
}
.btn-gray {
  color: #5a5a5a;
  background: var(--bs-white);
}
.btn-gray:hover {
  color: #fff;
  background: #5a5a5a;
}
.btn-red {
  background: #ed1d51;
  color: var(--bs-white);
  border: 1px solid #fffbe3;
  min-width: 150px;
}
.btn-red:hover {
  background: #000;
  border: 1px solid #fffbe3;
}
.btn-green {
  background: #299203;
  border: 1px solid var(--bs-white);
  color: var(--bs-white);
  border-radius: 1.2rem;
  min-width: 300px;
  font-size: 2.2rem;
}
.btn-green:hover {
  background: var(--bs-black);
  border: 1px solid var(--bs-white);

  color: var(--bs-white);
}
.c-heading {
  color: var(--bs-white);
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
}
.card1 {
  height: 100%;
}
.border_b,
.border-b {
  border-bottom: 1px solid #3b3b3b;
}
.card1 img {
  width: 100%;
}
.card_name {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #656565;
}
.sub-bold {
  font-weight: 300;
  text-align: center;
  margin: 1rem 0 3rem 0;
}
/* .img-radius img{
    border-radius: 2rem;
  } */

.normal_p {
  font-size: 14px;
  font-weight: 400;
}
.icon-main {
  display: flex;
  gap: 2rem;
  width: 90%;
}
.icon-div {
  width: 58px;
  height: 58px;
  min-width: 58px;
}
.icon-content h2 {
  font-size: 2.1rem;
  color: #606060;
  font-weight: 600;
  margin: 1.5rem 0;
}
.icon-content p {
  font-size: 1.4rem;
  font-weight: 400;
  color: #606060;
}
.test-div {
  padding: 4rem;
  border-radius: 1.5rem;
  background: rgb(36, 36, 36);
  background: linear-gradient(
    180deg,
    rgba(36, 36, 36, 1) 0%,
    rgba(0, 0, 0, 1) 90%
  );
}
.test-div img:first-child {
  height: 30px;
  margin-bottom: 2rem;
}
.star_img {
  height: 18px;
  margin-bottom: 2rem;
}
.test-div p {
  font-size: 1.6rem;
  color: var(--bs-white);
  font-weight: 300;
  position: relative;
  padding-bottom: 1.8rem;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}
.test-div h4 {
  font-size: 20px;
  font-weight: 500;
  color: var(--bs-white);
}
.test-div h6 {
  font-weight: 400;
}
.swiper-slide {
  transition: all 0.4s;
}
.top-sec {
  background: url(../../images/top-banner.webp) center center / cover
    no-repeat;
  height: 500px;
  display: flex;
  align-items: center;
}
.form-sec {
  background: url(../../images/form-banner.webp) center center / cover
    no-repeat;
}
/* .test-div p::after{
    content: '';
    position: absolute;
    bottom: 0;
    left:50%;
    transform: translateX(-50%);
    width: 60px;
    height: 1px;
    background: #d6d6d6;
  } */
.bg-gray {
  background: #e6e6e6;
}
.f-400 {
  font-weight: 400;
}
.btn-black {
  background: var(--bs-black);
  color: var(--bs-white);
  padding: 1.4rem 4.4rem;
  border-radius: 2rem;
}
.btn-black:hover {
  background: #444444;
  color: var(--bs-white);
}
form input {
  border: 2px solid #d6d6d6;
  padding: 1.2rem 2rem;
  border-radius: 29px;
  box-shadow: none;
  outline: none;
  font-size: 1.6rem;
  font-weight: 400;
}
form input::placeholder {
  color: #313131;
  opacity: 0.5;
}
form input[type="submit"] {
  background: #ed1d51;
  color: #fff;
  font-weight: 600;
  outline: none;
  border: none;
  padding: 1.3rem 3rem;
  width: 100%;
  font-size: 1.8rem;
}
.form-sec {
  padding: 12rem 0;
}
footer {
  padding-top: 2.1rem;
  border-top: 3px solid var(--bs-black);
  background: #f6f6f6;
}
.flex-logo {
  display: flex;
  gap: 1.8rem;
  align-items: center;
}
.footer-logo-space {
  margin-top: 63px;
}

footer ul {
  list-style: none;
  padding: 0;
}
footer ul li {
  margin-bottom: 1rem;
}
footer ul li a,
.copyright p {
  font-weight: 400;
  text-decoration: none;
}
footer h4,
footer ul li a,
footer .copyright p {
  color: #0d3862;
  font-size: 16px;
}
footer h4 {
  margin-bottom: 1.2rem;
  font-size: 18px;
  font-weight: 700;
  color: #0d3862;
}
.flex-social {
  display: flex;
  gap: 24px;
}
.flex-social li {
  width: 50px;
}
.copyright {
  border-top: 1px solid #4e4e4e;
  padding-top: 1.3rem;
  margin-top: 2.5rem !important;
  border-bottom: 1px solid #4e4e4e;
  margin-bottom: 10px;
}
.copyright p {
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 1rem;
}
.scrolled {
  top: 0;
  padding-top: 0;
  z-index: 999;
  background: #000;
}
.scrolled .navbar-expand-lg {
  background: #000;
}
.navbar-toggler-icon {
  background-image: url(../../images/bar-menu.svg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-image: url(../../images/cross-bar.svg) !important;
  transition: all 0s !important;
}
.no-cardp .card_name {
  display: block;
  border: none;
}
.no-cardp p {
  font-weight: 200;
  margin-top: 1.8rem;
}
.swiper-pagination-bullet {
  background: #b1afb0;
  opacity: 1;
  width: 12px;
  height: 12px;
}
.swiper-pagination-bullet-active {
  background: #ed1d51;
}
footer .col-md-4, footer .col-md-2{
  text-align: left;
}
.logo-sec .mobile-header_btn{
  display: none;
}
@media (max-width: 992px) {
  .navbar-collapse.show {
    transform: translateX(0ch) !important;
  }
  .nav-flex{
    flex-direction: row-reverse;
  }
  .logo-sec .navbar-brand{
   display: none;
  }
  .logo-sec .mobile-header_btn{
    display: flex;
    gap: 1rem;
  }
  .red_mobile_btn{
    background: #ED1D51;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 0.28rem 2.2rem;
  }
  .green_btn{
    background: #299203;

  }
  header .navbar-nav .nav-link{
    text-align: left;
  }
  header .navbar-nav .nav-item:last-child a{
    text-align: center;
  }
  header .navbar-nav {
    gap: 1.5rem;
  }
  .navbar-collapse {
    position: fixed;
    top: 68px;
    left: 0px;
    width: 84%;
    box-shadow: 0 0 50px rgb(24 24 24 / 20%);
    -webkit-box-shadow: 0 0 50px rgb(24 24 24 / 20%);
    -moz-box-shadow: 0 0 50px rgb(24 24 24 / 20%);
    height: 100%;
    z-index: 99999;
    transition: 0s ease-in-out !important;
    transition: 2s ease !important;
    transform: translateX(-35ch);
    transition-property: transform !important;
    transition-duration: 0.2s !important;
    transition-delay: 0.01s !important;
    background: #000;
    padding: 1.4rem;
    color: var(--white);
    padding-top: 4rem;
  }
  .navbar-toggler {
    transition: none;
    border: 1px solid #fff;
  }
  .navbar-toggler-icon {
    width: 2.5em;
    height: 2.5em;
  }
  header .navbar-nav .nav-item::after {
    display: none;
  }
  header .navbar-nav .nav-item:last-child {
    width: 130px;
  }
}

@media (max-width: 767px) {
  /* .home-banner{
    background: url("../../images/HeroMobile.jpg") center center/ cover no-repeat;
} */
.home-banner .join_btn{
    height: auto !important;

}
.home-banner .join_btn .btn-blue{
  display: block !important;
  height: auto !important;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  top: 110%;
  font-size: 16px;
  min-width: 120px;
  padding: 5px;
  position: absolute;
  top: 110%;

}
  .home-banner h2 {
    font-size: 2rem;
    font-weight: 700;
  }
  .home-banner {
    padding-top: 8rem;
    padding-bottom: 8rem;
    background-size: cover;
    height: auto;
    margin-top: 6rem;
    position: relative;
    z-index: -1;
  }
  .home-banner .btn-gray{
background: #248BCD;
color: #fff;
font-size: 16px;
font-weight: 400;
min-width: 135px;
height: 50px;

  }
  .border-b.no-cardp{
    border:1px solid #3b3b3b
  }
  .c-heading {
    font-size: 2.4rem;
  }
  .footer-logo-space {
    margin-top: 45px;
    margin-bottom: 4rem;
  }
  .grab-sec {
    /* padding-top: 0; */
    margin-top: 5rem;
  }
  .card_name p {
    font-size: 1.6rem;
    text-align: left;
  }
  .card_name img {
    height: 20px;
  }
  .card_u{
    padding-bottom: 0;
  }
  .border_b, .border-b {
    border-bottom: none;
}
  .form-sec {
    /* background: url(../../images/form-mobile.webp) center center / cover
      no-repeat;
      padding: 6rem 0;
      height: 900px; */
      padding: 6rem 0;
      height: 510px;
  }
  .top-sec {
    /* background: url(../../images/top-mobile.webp) center center / cover
      no-repeat;

    height: 750px;
    align-items: start; */
    height: 357px;
    align-items: start;
    background-size: auto;
    background-position: 21% 16%;
  }
  .btn-green {
    font-size: 1.8rem;
    min-width: 260px;

  }
  .test-div{
    text-align: center !important;
  }
  .footer-logo img{
margin-bottom: 2rem;
  }
  footer h4,footer h4, footer ul li a, footer .copyright p{
    font-size: 14px;
  }
  footer ul li{
    margin-bottom: 0;
  }
  .copyright{
    margin-top: 0 !important;
  }
  .footer-logo-space{
    justify-content: center;
    margin-top: 27px;
    margin-bottom: 3rem;
  }
  .btn-blue , .join_btn{
    display: none;
    height: 0px!important;
  }
}

.card1 a .card_name p {
  color: var(--bs-white)!important;
}

.btn-blue {
  color:white;
  background: #238BCC;
  position: relative;
  right: 17%;
  min-width: 150px;
  /* height: 40px; */
  font-size: 17px;
  top: 10px;
  padding: 7px;
  border: 1px solid white;

}
.btn-blue:hover {
  color: #fff;
  background: #238BCC;
  border: 1px solid white;
}

.join_btn{

  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 460px;
}
